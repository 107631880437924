<template>
  <table class="table is-bordered is-fullwidth">
    <tbody>
      <tr>
        <td>Pending balance</td>
        <td>Available balance</td>
        <td>Withdrawn balance</td>
      </tr>
      <tr>
        <th class="has-text-info">${{ affAccountData.balancePending }} USD</th>
        <th class="has-text-success">
          ${{ affAccountData.balanceAvailable }} USD
        </th>
        <th class="has-text-dark">
          ${{ affAccountData.balanceWithdrawn }} USD
        </th>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "AffAccountStatsTable",
  inject: ["affAccountProviderData"],
  computed: {
    affAccountData() {
      return this.affAccountProviderData().affAccountData;
    }
  }
};
</script>
